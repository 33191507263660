import { Flex, Group } from '@moo/components'
import type { ProductCollection as ProductCollectionType } from '@moo/types'
import { ProductCard } from '../ProductCard'

type Props = {
  collection: ProductCollectionType
}

export const ProductCollection = ({ collection }: Props) => {
  const { id, products } = collection

  return (
    <Group mb="sm">
      <Flex gap="sm">
        {products.map((product) => (
          <ProductCard
            key={product.id}
            product={product}
            cta={{
              href: `/products/${id}/${product.id}`,
              text: 'See details',
            }}
          />
        ))}
      </Flex>
    </Group>
  )
}
