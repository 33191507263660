import { ProductCollection as ProductCollectionType } from '@moo/types'
import { ProductCollection } from '../components'

export type ProductsListingPageProps = {
  collections: ProductCollectionType[]
}

export const ProductsListingPage = ({
  collections,
}: ProductsListingPageProps) => {
  return (
    <>
      {collections.map((collection) => (
        <ProductCollection key={collection.id} collection={collection} />
      ))}
    </>
  )
}
