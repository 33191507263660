import { NextPage } from 'next'
import {
  ProductsListingPage,
  type ProductsListingPageProps,
  plpGetStaticProps,
} from '@moo/products'

const Page: NextPage<ProductsListingPageProps> = ({ collections }) => {
  return <ProductsListingPage collections={collections} />
}

export const getStaticProps = plpGetStaticProps

export default Page
