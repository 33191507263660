import Link from 'next/link'
import { PropsWithChildren } from 'react'
import { Badge, Box, Button, Card, Group, Image, Text } from '@moo/components'
import { Product } from '@moo/types'

type Props = PropsWithChildren<{
  product: Product
  cta?: {
    text: string
    href: string
  }
}>

export const ProductCard = ({ product, cta, children }: Props) => {
  const { description, image, name, price, tag } = product

  return (
    <Card shadow="md" radius="sm">
      <Card.Section>
        <Image src={image} height={320} alt={name} />
      </Card.Section>

      <Card.Section p="sm" flex={1}>
        <Group justify="space-between">
          <Text fw={500}>{name}</Text>
          <Text fw={500}>${price.toFixed(2)}</Text>
        </Group>

        <Badge color={tag.color}>{tag.text.toUpperCase()}</Badge>

        <Text size="sm" c="dimmed" mt="sm">
          {description}
        </Text>

        {cta && (
          <Button component={Link} mt="md" radius="sm" href={cta.href}>
            {cta.text}
          </Button>
        )}

        {children && (
          <Box mt="md" w="100%">
            {children}
          </Box>
        )}
      </Card.Section>
    </Card>
  )
}
